/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */
import React from "react";
import {ThemeProvider} from "styled-components";
import theme from "./src/styles/theme";

export const wrapRootElement = ({element}) => (
    <ThemeProvider theme={theme}>
        {element}
    </ThemeProvider>
)
