import {breakpoints} from "./breakpoints";

export type ThemeType = typeof theme; // This is the type definition for my theme object.

const colors = {
    greenLight: "#88B726",
    greyLightest: "#f8f9f8",
    green: "#134726",
    greyLight: "#F2F3F2",
    grey: "rgba(85, 92, 88, 0.95);"
}

const effects = {
    boxShadow: "0px 4px 40px rgba(19, 71, 38, 0.12)"
}

const sizes = {
    p: {
        "font-size": "16px",
        "line-height": "27px"
    },
    pSmall: {
        "font-size": "14px",
        "line-height": "20px"
    }

}

const theme = {
    colors,
    effects,
    sizes,
    breakpoints
}

export default theme;